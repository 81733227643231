export const getUtilsCurrentYear = () => new Date().getFullYear();

export const getEventDateString = function (eventDateString: string) {
    let currentDateString = '';

    if (typeof(eventDateString) !== 'undefined' && eventDateString != null && eventDateString.length > 0) {
        const currentDate = new Date(eventDateString);

        currentDateString = `${currentDate.toLocaleDateString( "en-ZA", { 
            year: "numeric",
            month: "long",
            day: "numeric"
        })}`;
    }
    return currentDateString;
};

export const zaIdToDateOfBirth = function(dateString: string | null  | undefined): Date{
    let result = new Date();

    if (typeof(dateString) !== 'undefined' && dateString != null) {
        const pattern = /[0-9]{2}/g;
        const matches = dateString.match(pattern);

        if (matches != null) {
            const year = `19${matches[0]}`;
            const month = matches[1];
            const day = matches[2];

            result = new Date(`${year}/${month}/${day}`);
        }
       
    }
   
    return result;
}

export  function formatDate(date: Date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('/');
}