export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"src":"/analytics/google-tag-manager.js","type":"text/javascript"},{"src":"https://www.googletagmanager.com/gtag/js?id=AW-10804316532","async":true,"type":"text/javascript"},{"src":"/analytics/google-ad.js","defer":true,"type":"text/javascript"}],"noscript":[{"innerHTML":"<!-- Google Tag Manager (noscript) -->\n                    <noscript><iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-MXPC7K\"\n                    height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe></noscript>\n                    <!-- End Google Tag Manager (noscript) -->","body":true}],"templateParams":{"separator":"-"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false