export class ServerValidationError extends Error {
    constructor(message?: string) {
        super();
        this.message = `Invalid Server Validation.`;

        if (typeof(message) !== 'undefined' && message != null) {
            this.message = message;
        } ;
    }
}

export class EmailValidatorError extends Error {
    constructor(message?: string) {
        super();
        this.message = `Please enter an email address.`;

        if (typeof(message) !== 'undefined' && message != null) {
            this.message = message;
        } ;
    }
}

export class NotEmptyValidatorError extends Error {
    constructor(message?: string) {
        super();
        this.message = `Please fill out this field.`;

        if (typeof(message) !== 'undefined' && message != null) {
            this.message = message;
        } ;
    }
}

export class OptionNotSelectedValidatorError extends Error {
    constructor(message?: string) {
        super();
        this.message = `Please select an item from the list.`;

        if (typeof(message) !== 'undefined' && message != null) {
            this.message = message;
        } ;
    }
}


export class AlphaNumericError extends Error {
    constructor(message?: string) {
        super();
        this.message = message;

        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class ZAPhoneNumberError extends Error {
    constructor(message?: string) {
        super();
        this.message = 'Please enter a phone number in the following format: 0115551234 or +27821234567.';

        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class ContactNumberPhoneNumberError extends Error {
    constructor(message?: string) {
        super();
        this.message = 'Please enter a phone number in the following format: 0115551234 or +27821234567.';

        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class InternationalPhoneNumberError extends Error {
    constructor(message?: string) {
        super();
        this.message = 'Please enter a valid international phone number.';

        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class PasswordError extends Error {
    constructor(message?: string) {
        super();
        this.message = 'Please enter a password that complies with the hint rules.';

        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class PasswordComparatorError extends Error {
    constructor(message?: string) {
        super();
        this.message = 'Password and Confirmation Passwords do not match.';

        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class CharacterLengthError extends Error {
    constructor(message?: string) {
        super();
        this.message = 'The length of this field should not exceed 50 characters.';

        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class InvalidUnicodeCharacterError extends Error {
    constructor(message?: string) {
        super();
        this.message = 'Please enter a valid character.';

        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class InvalidDateError extends Error {
    constructor(message? : string) {
        super();
        this.message = 'Please enter date.';
        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class InvalidCheckedError extends Error {
    constructor(message? : string) {
        super();
        this.message = 'Please select one of the options.';
        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class InvalidZaPostalCodeError extends Error {
    constructor(message? : string) {
        super();
        this.message = 'Please enter a postal code.';
        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class InvalidLegalCheckError extends Error {
    constructor(message? : string) {
        super();
        this.message = 'Please accept the terms.';
        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class SubProgrammeConfigurationError extends Error {
    constructor(message? : string) {
        super();
        this.message = 'Parsing of SubProgram Configuration Fields.';
        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class NumericError extends Error {
    constructor(message? : string) {
        super();
        this.message = 'Please enter a number.';
        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}

export class InvalidIdError extends Error {
    constructor(message? : string) {
        super();
        this.message = 'Please enter a valid ID number.';
        if (typeof(message) !== 'undefined' 
            && message != null) {
            this.message = message;
        } ;
    }
}