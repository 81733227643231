import {    
    NotEmptyValidatorError, 
    OptionNotSelectedValidatorError, 
    EmailValidatorError, 
    AlphaNumericError, 
    ZAPhoneNumberError, 
    PasswordError, 
    PasswordComparatorError,
    InvalidUnicodeCharacterError,
    InternationalPhoneNumberError,
    InvalidDateError,
    InvalidZaPostalCodeError,
    InvalidLegalCheckError,
    ContactNumberPhoneNumberError,
    NumericError
} from "~/classes/validation-errors";
import { FormBooleanValues, Guid } from "./enums";

// ValidatorFunctions
export function notEmptyValidator(input: string | number | boolean | null , isMandatory: boolean = false): boolean | Error {
    let outcome: boolean | Error = true;
    //debugger;
    if (typeof(input) !== 'boolean') {
        if (isMandatory && (input == null || typeof(input) === 'undefined')) {
            // Called isRequired Validator 
            return outcome = new NotEmptyValidatorError();
        }
    
        if (typeof(input) !== 'undefined' && input != null ) {
            if (!input ) {
                outcome = false;
            }
    
            if (!outcome) {
                outcome = new NotEmptyValidatorError();
            }
        }
        else {
            if (isMandatory) {
                // Called isRequired Validator 
                outcome = new NotEmptyValidatorError();
            }
        }
    }
    else {
        if (isMandatory !== input) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
    
    return outcome;
}

export const isRequired = notEmptyValidator;

export function optionSelected (input: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome: boolean | Error = true; 
    input = input.trim();
    let inputCheck = input;
    if (input == Guid.Empty){
        input = '';
    }
    if (input == Guid.DeadBeef){
        debugger;
        //input = '';
    }

    if (input != null && typeof(input) === 'string' && input == '{"Value":"00000000-0000-0000-0000-000000000000","Text":""}') 
    {
        return outcome = new NotEmptyValidatorError();
    }

    
    if (inputCheck != null && typeof(inputCheck) === 'object' && (inputCheck as IFormTypeAheadItem) != null) {
        let item = inputCheck as IFormTypeAheadItem;
        if (item.Value == Guid.Empty)
        {
            inputCheck = item.text;
        }
    }

    if (isMandatory && (inputCheck == null || typeof(inputCheck) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }

    if (typeof(inputCheck) !== 'undefined' && inputCheck != null && inputCheck.length !== 0) {
        if (!inputCheck) {
            outcome = false;
        }

        if (!outcome) {
            outcome = new OptionNotSelectedValidatorError();
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
    return outcome;
}

export function emailValidator(input: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome: boolean | Error = true;
    
    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }

    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) {
        const emailArray = input.split(".");
        const domain = emailArray[emailArray.length - 1];
        const cozaRegEx = /(coza)/ig;

        if (cozaRegEx.test(domain)) {
            outcome = new EmailValidatorError(".coza is not a valid domain");
            return outcome;
        }

        const emailRegEx = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        outcome = emailRegEx.test(input);

        if (!outcome) {
            outcome = new EmailValidatorError();
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
    return outcome;
}

export function alphaNumericValidator(input: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome: boolean | Error = true;

    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }
    
    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) {
        
        const alphaNumericRegEx = /^([a-zA-Z0-9]+[\s|\-]?[a-zA-Z0-9])+$/;
        outcome = alphaNumericRegEx.test(input);
        if (!outcome) {
            outcome = new AlphaNumericError(`"${input}" is not a valid alpha numeric value.`);
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
    
    return outcome;
}

export function zaPhoneNumberValidator(input: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome: boolean | Error = true;

    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }


    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) {
        const phoneNumberRegEx = /^(([\+]?[2][7])|([0]))[1-9][0-9]{8}$/;
        if (!phoneNumberRegEx.test(input)) {
            outcome = new ZAPhoneNumberError();
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
    
    return outcome;
}

export function contactFormPhoneNumberValidator(input: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }

    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) {
        outcome = (input === "__________") ? null : input;
        if (!outcome) {
            outcome = new ContactNumberPhoneNumberError();
        }
        
        const phoneNumberRegEx = /^(([\+]?[2][7])|([0]))[1-9][0-9]{8,10}$/;
        if (!phoneNumberRegEx.test(input)) {
            outcome = new ContactNumberPhoneNumberError();
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
   
    return outcome;
}

export function localAndInternationalPhoneNumberValidator(input: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }

    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) {
        
        const phoneNumberRegEx = /^(([\+]?[1-9][0-9]{1,4})|([0][1-9]{2}))[0-9]{7,10}$/;
        if (!phoneNumberRegEx.test(input)) {
            outcome = new ZAPhoneNumberError();
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
   
    return outcome;
}

export function isValidPhoneNumber(input: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }

    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) {
        
        const phoneNumberRegEx = /^([\+]?)[0-9]{11,15}$/;
        if (!phoneNumberRegEx.test(input)) {
            outcome = new InternationalPhoneNumberError();
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
   
    return outcome;
}

/**
 * 
 * @param initial 
 * @param comparator 
 * @param isMandatory will always be false for a ConditionalComparatorValidator
 * @returns 
 */
export function internationalStudentHasWorkPermitConditionalComparatorValidator(initial: any, comparator: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    // if (isMandatory && (initial == null || typeof(initial) === 'undefined')) {
    //     // Called isRequired Validator 
    //     return outcome = new NotEmptyValidatorError();
    // }

    if (isMandatory && (comparator == null || typeof(comparator) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError("Please fill out the comparing field.");
    }

     // TODO: this is specific Condition, on a Comparator value to  Validate 
    // "Sender": "gibs_internationalapplicant_brite_04_47_internationalstudent" is a type=boolean  and value is true=1  then this "gibs_workstudypermitind"
    if (comparator === '1') {
        //Then in here we will validate  initial value to be a file base64 value or IFile interface object. the current Field Value is required.
        if (typeof(initial) === 'undefined' || initial == null || (initial instanceof String && initial.length === 0)) {
            return outcome = new NotEmptyValidatorError();
        }
    }

   
    return outcome;
}

export function passwordValidator(input: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }


    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) {
        let lowercaseRegEx = /[a-z]+/g;
        let uppercaseRegEx = /[A-Z]+/g;
        let numericRegEx = /[0-9]+/g;
        let specialRegEx = /[!@#\$\%\^\&\*(),.]/g;
        let exludeOthersRegEx = /[^a-zA-Z0-9!@#\$\%\^\&\*(),.]/g;
        
       
        if (!lowercaseRegEx.test(input)) {
            return outcome = new PasswordError("Please add at least one lowercase alphabetic character.");
        }
        
        if (!uppercaseRegEx.test(input)) {
            return outcome = new PasswordError("Please add at least one uppercase alphabetic character.");
        }
        
        if (!numericRegEx.test(input)) {
            return outcome = new PasswordError("Please add at least one numeric character.");
        }
        
        if (!specialRegEx.test(input)) {
            return outcome = new PasswordError("Please add at least one special character. ('!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '.'");
        }

        if (exludeOthersRegEx.test(input)) {
            return outcome = new PasswordError("Not a valid listed special character.");
        }

        if (input.length < 8) {
            return outcome = new PasswordError("Please enter a password length of at least eight characters.");
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
   
    return outcome;
}

export function inputMaxLength50CharactersValidator(input: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }


    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) {
    
        const value = new String(input).valueOf();
        if (value.length > 50) {
            outcome = new CharacterLengthError("The length of this field can not exceed 50 characters.");
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
   
    return outcome;
}

export function inputMaxLength160CharactersValidator(input: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }


    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) {
    
        const value = new String(input).valueOf();
        if (value.length >160) {
            outcome = new CharacterLengthError("The length of this field can not exceed 160 characters.");
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
   
    return outcome;
}

export function inputMaxLength320CharactersValidator(input: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }


    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) {
    
        const value = new String(input).valueOf();
        if (value.length > 320) {
            outcome = new CharacterLengthError("The length of this field can not exceed 320 characters.");
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
   
    return outcome;
}

export function unicodeNameValidator(input: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }


    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) {
        // \u00C0-\u00FF Latin-1 Supplement
        // \u0100-\u017F Latin Extended-A
        // \u0180-\u024F Latin Extended-B
        // \u1E00-\u1EFF Latin Extended Additional

        const alphaNumericRegEx = /^(([a-zA-Z\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF\u1E00-\u1EFF])|([a-zA-Z\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF\u1E00-\u1EFF]+[\s|\-]{0,1}[a-zA-Z\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF\u1E00-\u1EFF]+))+$/ug;
        outcome = alphaNumericRegEx.test(input);
        if (!outcome) {
            outcome = new InvalidUnicodeCharacterError("Please enter a valid alphabetic character. Cannot end with a space or a dash.");
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
   
    return outcome;
}

export function isValidDate(input: Date | null = null, isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;
    if (isMandatory && (input == null || typeof(input) === 'undefined' || (input as String) === '')) {
        // Called isRequired Validator 
        return outcome = new InvalidDateError();
    }
    return outcome;
}

// TODO:BF remove this one as it does squat
export function  optionalValidator (input: Date | null = null, isMandatory: boolean = false) : boolean | Error {
    let outcome  = true;
    return outcome;
}


export function isChecked(input: FormBooleanValues | null = null, isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    
    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new InvalidCheckedError();
    }

    return outcome;
}

export function zaPostalCodeValidator(input: string | null = null, isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;
    
    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        return outcome = new InvalidZaPostalCodeError();
    }

    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) { 
        const reCode = /^[0-9]{4}$/;
        outcome = reCode.test(input);
        if (!outcome) {
            outcome = new InvalidZaPostalCodeError();
        }
    }

    if (isMandatory && input.length === 0 ){
        return outcome = new InvalidZaPostalCodeError();
    }

    return outcome;
}

export function isLegalTermsAccepted(input: FormBooleanValues | null = null, isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        return outcome = new InvalidLegalCheckError();
    }

    if (input === FormBooleanValues.False ) {
        outcome = new InvalidLegalCheckError();
    }

    return outcome;
}

export function numericValidator(input: string  | null = null, isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    if (isMandatory && (input == null || typeof(input) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }
    
    if (typeof(input) !== 'undefined' && input != null && input.length !== 0) {
        
        const numericRegEx = /^[0-9]+$/;
        outcome = numericRegEx.test(input);
        if (!outcome) {
            outcome = new NumericError();
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
   
    return outcome;
}
// Comparator Functions
export function passwordComparatorValidator(initial: string = "", comparator: string = "", isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;

    if (isMandatory && (initial == null || typeof(initial) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }

    if (isMandatory && (comparator == null || typeof(comparator) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError("Please fill out the comparing field.");
    }

    if (typeof(initial) !== 'undefined' && initial != null && initial.length !== 0) {
        const asteriskRegEx = /\*/g;
        const dollarRegEx = /\$/g;
        const hatRegEx = /\^/g;
        const openBracketRegEx = /\(/g;
        const closeBracketRegEx = /\)/g;
        const questionMarkRegEx = /\?/g;

        let sanitisedInput = initial.replace(asteriskRegEx, '\\*');
        sanitisedInput = sanitisedInput.replace(dollarRegEx, '\\$');
        sanitisedInput = sanitisedInput.replace(hatRegEx, '\\^');
        sanitisedInput = sanitisedInput.replace(openBracketRegEx, '\\(');
        sanitisedInput = sanitisedInput.replace(closeBracketRegEx, '\\)');
        sanitisedInput = sanitisedInput.replace(questionMarkRegEx, '\\?');


        let initialRegEx = new RegExp(`^${sanitisedInput}$`, 'g');
        
       
        if (!initialRegEx.test(comparator)) {
            outcome = new PasswordComparatorError();
        }
    }
    else {
        if (isMandatory) {
            // Called isRequired Validator 
            outcome = new NotEmptyValidatorError();
        }
    }
   
    return outcome;
}

// ConditionalComparator Functions
export function isEqualConditionFunction(   validationRuleId: string, 
                                                                                            sourceValue: string | boolean| number | Date| null, 
                                                                                            conditionalValue: string | boolean| number | Date| null | undefined, 
                                                                                            comparatorRuleId: string | undefined,
                                                                                            comparatorValue: string | boolean| number | Date| null | undefined, 
                                                                                            reactionRuleId: string  | undefined ): boolean {
    let result = false;
    if (conditionalValue == comparatorValue) {
        result = true;
    }
    return result;
}

export function isSouthAfricanId(idNumber: string | null = null, isMandatory: boolean = false): boolean | Error {
    let outcome:boolean | Error = true;
    if (isMandatory && (idNumber == "" || idNumber == null || typeof(idNumber) === 'undefined')) {
        // Called isRequired Validator 
        return outcome = new NotEmptyValidatorError();
    }

    if (typeof(idNumber) !== 'undefined' && idNumber != null && idNumber.length > 0){
        if (idNumber?.length == 13 && Number(idNumber)) 
        {
            // get first 6 digits as a valid date
            var tempDate = new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6));
            
            var id_date = tempDate.getDate();
            var id_month = tempDate.getMonth();
    
            if (!((tempDate.getYear() == idNumber.substring(0, 2)) && (id_month == idNumber.substring(2, 4) - 1) && (id_date == idNumber.substring(4, 6)))) {
                outcome = new InvalidIdError();
            }
    
            // apply Luhn formula for check-digits
            var tempTotal = 0;
            var checkSum = 0;
            var multiplier = 1;
            for (var i = 0; i < 13; ++i) {
                tempTotal = parseInt(idNumber.charAt(i)) * multiplier;
                if (tempTotal > 9) {
                    tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
                }
                checkSum = checkSum + tempTotal;
                multiplier = (multiplier % 2 == 0) ? 1 : 2;
            }
            if ((checkSum % 10) != 0) {
                outcome = new InvalidIdError();
            }
        }
        else{
            outcome = new InvalidIdError();
        }
    }
  
    return outcome;
}