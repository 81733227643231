import { default as _91_46_46_46slug_93srQP6gFDJPMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/[...slug].vue?macro=true";
import { default as _404ezTsTHNFvzMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/404.vue?macro=true";
import { default as index9mkHrvYGFyMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/a-solution-for-your-organisation/index.vue?macro=true";
import { default as _91tab_931R1kDBM3a3Meta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/account/[tab].vue?macro=true";
import { default as indexgy7oYecWA8Meta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/account/index.vue?macro=true";
import { default as _91form_93MhuLIGVJlDMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/apply/[form].vue?macro=true";
import { default as completetkfm6zmJFBMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/apply/complete.vue?macro=true";
import { default as indexT9qR4KkhTNMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/azure/index.vue?macro=true";
import { default as indexbqjbpCSBswMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/coaching-listing/index.vue?macro=true";
import { default as indexPd0aAGlJ9rMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/content/index.vue?macro=true";
import { default as index9gDpJffAx3Meta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/course-finder/index.vue?macro=true";
import { default as indextlSVC4TdkaMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/course-finder/results/index.vue?macro=true";
import { default as _91tab_934OYzxxikS2Meta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/course-listing/[tab].vue?macro=true";
import { default as _91tab_93mjvDNem3V8Meta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/faculty/[tab].vue?macro=true";
import { default as indexebUt9TUKzUMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/faculty/index.vue?macro=true";
import { default as indexUamVJaeWY1Meta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/human-acumen-booklet/index.vue?macro=true";
import { default as indexdWruKyuW12Meta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/index.vue?macro=true";
import { default as indexYXWb2iWXYwMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/info-central/index.vue?macro=true";
import { default as indexc0HJKMQacxMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/legal/index.vue?macro=true";
import { default as indexT9VX6FabWkMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/news/index.vue?macro=true";
import { default as confirmationKVhhpEGGSHMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/payment/confirmation.vue?macro=true";
import { default as indexfgID6MSgUeMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/payment/index.vue?macro=true";
import { default as indexrzoindKoEeMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/preview/index.vue?macro=true";
import { default as indexS6oGGpp9HBMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/preview/programmes/[tab]/index.vue?macro=true";
import { default as _91tab_93LCXbg3di2QMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/profile/[tab].vue?macro=true";
import { default as indexnSfXoXJVnmMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/profile/index.vue?macro=true";
import { default as index2Uhqr4YMcQMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/programmes/[tab]/index.vue?macro=true";
import { default as reset_45passwordRQMvn3Ja1XMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/reset-password.vue?macro=true";
import { default as font_45testXOqVYP0amfMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/sample/font-test.vue?macro=true";
import { default as indexk4KQgO4HUhMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/sample/index.vue?macro=true";
import { default as notificationGhjjW41SVgMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/sample/notification.vue?macro=true";
import { default as programmesTest9rqvFH26fFMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/sample/programmesTest.vue?macro=true";
import { default as indexoOIHxLKGBJMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/search/index.vue?macro=true";
import { default as indexAzWEOmzAVaMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/settings/index.vue?macro=true";
import { default as index2N18UYwEtbMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/sitemap/index.vue?macro=true";
import { default as user_45verificationZ33bZbVtJcMeta } from "C:/GibsAgent/_work/4/s/frontend/web/src/pages/user-verification.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93srQP6gFDJPMeta?.name ?? "slug",
    path: _91_46_46_46slug_93srQP6gFDJPMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93srQP6gFDJPMeta || {},
    alias: _91_46_46_46slug_93srQP6gFDJPMeta?.alias || [],
    redirect: _91_46_46_46slug_93srQP6gFDJPMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _404ezTsTHNFvzMeta?.name ?? "404",
    path: _404ezTsTHNFvzMeta?.path ?? "/404",
    meta: _404ezTsTHNFvzMeta || {},
    alias: _404ezTsTHNFvzMeta?.alias || [],
    redirect: _404ezTsTHNFvzMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: index9mkHrvYGFyMeta?.name ?? "a-solution-for-your-organisation",
    path: index9mkHrvYGFyMeta?.path ?? "/a-solution-for-your-organisation",
    meta: index9mkHrvYGFyMeta || {},
    alias: index9mkHrvYGFyMeta?.alias || [],
    redirect: index9mkHrvYGFyMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/a-solution-for-your-organisation/index.vue").then(m => m.default || m)
  },
  {
    name: _91tab_931R1kDBM3a3Meta?.name ?? "account-tab",
    path: _91tab_931R1kDBM3a3Meta?.path ?? "/account/:tab()",
    meta: _91tab_931R1kDBM3a3Meta || {},
    alias: _91tab_931R1kDBM3a3Meta?.alias || [],
    redirect: _91tab_931R1kDBM3a3Meta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/account/[tab].vue").then(m => m.default || m)
  },
  {
    name: indexgy7oYecWA8Meta?.name ?? "account",
    path: indexgy7oYecWA8Meta?.path ?? "/account",
    meta: indexgy7oYecWA8Meta || {},
    alias: indexgy7oYecWA8Meta?.alias || [],
    redirect: indexgy7oYecWA8Meta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91form_93MhuLIGVJlDMeta?.name ?? "apply-form",
    path: _91form_93MhuLIGVJlDMeta?.path ?? "/apply/:form()",
    meta: _91form_93MhuLIGVJlDMeta || {},
    alias: _91form_93MhuLIGVJlDMeta?.alias || [],
    redirect: _91form_93MhuLIGVJlDMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/apply/[form].vue").then(m => m.default || m)
  },
  {
    name: completetkfm6zmJFBMeta?.name ?? "apply-complete",
    path: completetkfm6zmJFBMeta?.path ?? "/apply/complete",
    meta: completetkfm6zmJFBMeta || {},
    alias: completetkfm6zmJFBMeta?.alias || [],
    redirect: completetkfm6zmJFBMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/apply/complete.vue").then(m => m.default || m)
  },
  {
    name: indexT9qR4KkhTNMeta?.name ?? "azure",
    path: indexT9qR4KkhTNMeta?.path ?? "/azure",
    meta: indexT9qR4KkhTNMeta || {},
    alias: indexT9qR4KkhTNMeta?.alias || [],
    redirect: indexT9qR4KkhTNMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/azure/index.vue").then(m => m.default || m)
  },
  {
    name: indexbqjbpCSBswMeta?.name ?? "coaching-listing",
    path: indexbqjbpCSBswMeta?.path ?? "/coaching-listing",
    meta: indexbqjbpCSBswMeta || {},
    alias: indexbqjbpCSBswMeta?.alias || [],
    redirect: indexbqjbpCSBswMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/coaching-listing/index.vue").then(m => m.default || m)
  },
  {
    name: indexPd0aAGlJ9rMeta?.name ?? "content",
    path: indexPd0aAGlJ9rMeta?.path ?? "/content",
    meta: indexPd0aAGlJ9rMeta || {},
    alias: indexPd0aAGlJ9rMeta?.alias || [],
    redirect: indexPd0aAGlJ9rMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/content/index.vue").then(m => m.default || m)
  },
  {
    name: index9gDpJffAx3Meta?.name ?? "course-finder",
    path: index9gDpJffAx3Meta?.path ?? "/course-finder",
    meta: index9gDpJffAx3Meta || {},
    alias: index9gDpJffAx3Meta?.alias || [],
    redirect: index9gDpJffAx3Meta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/course-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indextlSVC4TdkaMeta?.name ?? "course-finder-results",
    path: indextlSVC4TdkaMeta?.path ?? "/course-finder/results",
    meta: indextlSVC4TdkaMeta || {},
    alias: indextlSVC4TdkaMeta?.alias || [],
    redirect: indextlSVC4TdkaMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/course-finder/results/index.vue").then(m => m.default || m)
  },
  {
    name: _91tab_934OYzxxikS2Meta?.name ?? "course-listing-tab",
    path: _91tab_934OYzxxikS2Meta?.path ?? "/course-listing/:tab()",
    meta: _91tab_934OYzxxikS2Meta || {},
    alias: _91tab_934OYzxxikS2Meta?.alias || [],
    redirect: _91tab_934OYzxxikS2Meta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/course-listing/[tab].vue").then(m => m.default || m)
  },
  {
    name: _91tab_93mjvDNem3V8Meta?.name ?? "faculty-tab",
    path: _91tab_93mjvDNem3V8Meta?.path ?? "/faculty/:tab()",
    meta: _91tab_93mjvDNem3V8Meta || {},
    alias: _91tab_93mjvDNem3V8Meta?.alias || [],
    redirect: _91tab_93mjvDNem3V8Meta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/faculty/[tab].vue").then(m => m.default || m)
  },
  {
    name: indexebUt9TUKzUMeta?.name ?? "faculty",
    path: indexebUt9TUKzUMeta?.path ?? "/faculty",
    meta: indexebUt9TUKzUMeta || {},
    alias: indexebUt9TUKzUMeta?.alias || [],
    redirect: indexebUt9TUKzUMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/faculty/index.vue").then(m => m.default || m)
  },
  {
    name: indexUamVJaeWY1Meta?.name ?? "human-acumen-booklet",
    path: indexUamVJaeWY1Meta?.path ?? "/human-acumen-booklet",
    meta: indexUamVJaeWY1Meta || {},
    alias: indexUamVJaeWY1Meta?.alias || [],
    redirect: indexUamVJaeWY1Meta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/human-acumen-booklet/index.vue").then(m => m.default || m)
  },
  {
    name: indexdWruKyuW12Meta?.name ?? "index",
    path: indexdWruKyuW12Meta?.path ?? "/",
    meta: indexdWruKyuW12Meta || {},
    alias: indexdWruKyuW12Meta?.alias || [],
    redirect: indexdWruKyuW12Meta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexYXWb2iWXYwMeta?.name ?? "info-central",
    path: indexYXWb2iWXYwMeta?.path ?? "/info-central",
    meta: indexYXWb2iWXYwMeta || {},
    alias: indexYXWb2iWXYwMeta?.alias || [],
    redirect: indexYXWb2iWXYwMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/info-central/index.vue").then(m => m.default || m)
  },
  {
    name: indexc0HJKMQacxMeta?.name ?? "legal",
    path: indexc0HJKMQacxMeta?.path ?? "/legal",
    meta: indexc0HJKMQacxMeta || {},
    alias: indexc0HJKMQacxMeta?.alias || [],
    redirect: indexc0HJKMQacxMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: indexT9VX6FabWkMeta?.name ?? "news",
    path: indexT9VX6FabWkMeta?.path ?? "/news",
    meta: indexT9VX6FabWkMeta || {},
    alias: indexT9VX6FabWkMeta?.alias || [],
    redirect: indexT9VX6FabWkMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: confirmationKVhhpEGGSHMeta?.name ?? "payment-confirmation",
    path: confirmationKVhhpEGGSHMeta?.path ?? "/payment/confirmation",
    meta: confirmationKVhhpEGGSHMeta || {},
    alias: confirmationKVhhpEGGSHMeta?.alias || [],
    redirect: confirmationKVhhpEGGSHMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/payment/confirmation.vue").then(m => m.default || m)
  },
  {
    name: indexfgID6MSgUeMeta?.name ?? "payment",
    path: indexfgID6MSgUeMeta?.path ?? "/payment",
    meta: indexfgID6MSgUeMeta || {},
    alias: indexfgID6MSgUeMeta?.alias || [],
    redirect: indexfgID6MSgUeMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: indexrzoindKoEeMeta?.name ?? "preview",
    path: indexrzoindKoEeMeta?.path ?? "/preview",
    meta: indexrzoindKoEeMeta || {},
    alias: indexrzoindKoEeMeta?.alias || [],
    redirect: indexrzoindKoEeMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/preview/index.vue").then(m => m.default || m)
  },
  {
    name: indexS6oGGpp9HBMeta?.name ?? "preview-programmes-tab",
    path: indexS6oGGpp9HBMeta?.path ?? "/preview/programmes/:tab()",
    meta: indexS6oGGpp9HBMeta || {},
    alias: indexS6oGGpp9HBMeta?.alias || [],
    redirect: indexS6oGGpp9HBMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/preview/programmes/[tab]/index.vue").then(m => m.default || m)
  },
  {
    name: _91tab_93LCXbg3di2QMeta?.name ?? "profile-tab",
    path: _91tab_93LCXbg3di2QMeta?.path ?? "/profile/:tab()",
    meta: _91tab_93LCXbg3di2QMeta || {},
    alias: _91tab_93LCXbg3di2QMeta?.alias || [],
    redirect: _91tab_93LCXbg3di2QMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/profile/[tab].vue").then(m => m.default || m)
  },
  {
    name: indexnSfXoXJVnmMeta?.name ?? "profile",
    path: indexnSfXoXJVnmMeta?.path ?? "/profile",
    meta: indexnSfXoXJVnmMeta || {},
    alias: indexnSfXoXJVnmMeta?.alias || [],
    redirect: indexnSfXoXJVnmMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index2Uhqr4YMcQMeta?.name ?? "programmes-tab",
    path: index2Uhqr4YMcQMeta?.path ?? "/programmes/:tab()",
    meta: index2Uhqr4YMcQMeta || {},
    alias: index2Uhqr4YMcQMeta?.alias || [],
    redirect: index2Uhqr4YMcQMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/programmes/[tab]/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordRQMvn3Ja1XMeta?.name ?? "reset-password",
    path: reset_45passwordRQMvn3Ja1XMeta?.path ?? "/reset-password",
    meta: reset_45passwordRQMvn3Ja1XMeta || {},
    alias: reset_45passwordRQMvn3Ja1XMeta?.alias || [],
    redirect: reset_45passwordRQMvn3Ja1XMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: font_45testXOqVYP0amfMeta?.name ?? "sample-font-test",
    path: font_45testXOqVYP0amfMeta?.path ?? "/sample/font-test",
    meta: font_45testXOqVYP0amfMeta || {},
    alias: font_45testXOqVYP0amfMeta?.alias || [],
    redirect: font_45testXOqVYP0amfMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/sample/font-test.vue").then(m => m.default || m)
  },
  {
    name: indexk4KQgO4HUhMeta?.name ?? "sample",
    path: indexk4KQgO4HUhMeta?.path ?? "/sample",
    meta: indexk4KQgO4HUhMeta || {},
    alias: indexk4KQgO4HUhMeta?.alias || [],
    redirect: indexk4KQgO4HUhMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/sample/index.vue").then(m => m.default || m)
  },
  {
    name: notificationGhjjW41SVgMeta?.name ?? "sample-notification",
    path: notificationGhjjW41SVgMeta?.path ?? "/sample/notification",
    meta: notificationGhjjW41SVgMeta || {},
    alias: notificationGhjjW41SVgMeta?.alias || [],
    redirect: notificationGhjjW41SVgMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/sample/notification.vue").then(m => m.default || m)
  },
  {
    name: programmesTest9rqvFH26fFMeta?.name ?? "sample-programmesTest",
    path: programmesTest9rqvFH26fFMeta?.path ?? "/sample/programmesTest",
    meta: programmesTest9rqvFH26fFMeta || {},
    alias: programmesTest9rqvFH26fFMeta?.alias || [],
    redirect: programmesTest9rqvFH26fFMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/sample/programmesTest.vue").then(m => m.default || m)
  },
  {
    name: indexoOIHxLKGBJMeta?.name ?? "search",
    path: indexoOIHxLKGBJMeta?.path ?? "/search",
    meta: indexoOIHxLKGBJMeta || {},
    alias: indexoOIHxLKGBJMeta?.alias || [],
    redirect: indexoOIHxLKGBJMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexAzWEOmzAVaMeta?.name ?? "settings",
    path: indexAzWEOmzAVaMeta?.path ?? "/settings",
    meta: indexAzWEOmzAVaMeta || {},
    alias: indexAzWEOmzAVaMeta?.alias || [],
    redirect: indexAzWEOmzAVaMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: index2N18UYwEtbMeta?.name ?? "sitemap",
    path: index2N18UYwEtbMeta?.path ?? "/sitemap",
    meta: index2N18UYwEtbMeta || {},
    alias: index2N18UYwEtbMeta?.alias || [],
    redirect: index2N18UYwEtbMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: user_45verificationZ33bZbVtJcMeta?.name ?? "user-verification",
    path: user_45verificationZ33bZbVtJcMeta?.path ?? "/user-verification",
    meta: user_45verificationZ33bZbVtJcMeta || {},
    alias: user_45verificationZ33bZbVtJcMeta?.alias || [],
    redirect: user_45verificationZ33bZbVtJcMeta?.redirect,
    component: () => import("C:/GibsAgent/_work/4/s/frontend/web/src/pages/user-verification.vue").then(m => m.default || m)
  }
]