import { defineEventHandler, setResponseHeaders, isEvent } from 'h3';


export default defineEventHandler((event) => {

    if (isEvent(event)) {
        setResponseHeaders(event, {
            "Access-Control-Allow-Methods": "GET,HEAD,PUT,PATCH,POST,DELETE",
            "Access-Control-Allow-Origin": "*",
            'Access-Control-Allow-Credentials': 'true',
            "Access-Control-Allow-Headers": '*',
            "Access-Control-Expose-Headers": '*'
          });
    }
    
  }
)