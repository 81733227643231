import { getEventDateString } from "~/utils/date-functions";
import { currencyFormat } from "~/utils/currency-functions"; 

export default defineNuxtPlugin(() => {

    function dateFormat(date: string) {
        let formattedDate = "";
        const optionsDate: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "short",
            day: "numeric"
        };
    
        formattedDate = new Intl.DateTimeFormat("en-za", optionsDate).format(new Date(date));
        return formattedDate;
    }

    function isValidDate(d: Date) {
        return d instanceof Date && !isNaN(d);
    }

    function formatDateString(dateString: string | null | undefined): string {
        console.log(dateString)
        if (dateString && typeof(dateString) === "string") {
            const dateObject = new Date(dateString);
            if (isValidDate(dateObject)) {
                return dateObject.toISOString().split('T')[0];
            } else {
                console.log("Invalid Date");
                return '';
            }
        } else {
            return '';
        }
    }
    

    return {
        provide: {
            FuncGetEventDateString: getEventDateString,
            FuncDateFormat: dateFormat,
            FuncCurrencyFormat: currencyFormat, 
            FuncFormatDateString: formatDateString
        }
    }
})

