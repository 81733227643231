export enum ContactFormValidatorId {
    Email = 'Email',
    Name = 'Name',
    Surname = 'Surname',
    Reason = 'Reason',
    ContactNumber = 'ContactNumber',
    Message = 'Message',
    SubscribeToNewsletter = 'SubscribeToNewsletter'
}

export enum LoginFormValidatorId {
    LoginEmail = 'LoginEmail',
    LoginPassword = 'LoginPassword'
}

export enum SignupFormValidatorId {
    SignupEmail = 'SignupEmail',
    SignupPassword = 'SignupPassword',
    SignupConfirmedPassword = 'SignupConfirmedPassword',
    Title = 'Title',
    Name = 'Name',
    Surname = 'Surname',
    ContactNumber = 'ContactNumber',
    AllowMarketing = 'AllowMarketing',
    AcceptedTandC = 'AcceptedTandC'
}

export enum ChangePasswordFormValidatorId {
    CurrentPassword = 'CurrentPassword',
    NewPassword = 'NewPassword',
    ConfirmedPassword = 'ConfirmedPassword'
}

export enum ForgotPasswordFormValidatorId {
  ForgotPasswordEmail = 'ForgotPasswordEmail'
}

export enum ResetPasswordFormValidatorId {
    NewPassword = 'NewPassword',
    ConfirmedPassword = 'ConfirmedPassword'
}

export enum NewsletterSubscriptionFormValidatorId {
    SignupEmail = 'SignupEmail', 
    Name = 'Name',
    Surname = 'Surname' 
}

export enum ShareProgrammeFormValidatorId {
    Email = 'Email', 
    RecipientName = 'Name',
    RecipientSurname = 'Surname',
    SenderName = 'SenderName',
    SenderSurname = 'SenderSurname' 
}

export enum ApplicationClosedFormValidatorId {
    Name = 'Name', 
    Surname = 'Surname',
    Email = 'Email', 
    Message = 'Message',
    ContactNumber = 'ContactNumber',
    AlertMe = 'AlertMe' 
}

export enum FormSectionStates {
    Completed = "Completed",
    InProgress = "In Progress",
    Incomplete = "Incomplete",
    Optional = "Optional",
    ValidationFailed = "Missing Information"
}

export enum FormBooleanValues {
    True = "1",
    False = "2"
}

export enum FormValidator {
    Display= 'Display',
    DisplayAsMandatory = 'Display as Mandatory'
}

export enum FormReceiverType {
    FillIfEmpty = 'FillIfEmpty',
    CopyText = 'CopyText',
    CopyValue = 'CopyValue',
    ShowOtherField='showOtherField',
    ShowAltOtherField ='showAltOtherField'
}

export enum FormValidatorRuleType {
    Validator = 'Validator',
    Comparator = 'Comparator',
    ConditionalComparator = 'ConditionalComparator',
    Interaction = 'Interaction'
}

export enum CRMEntity {
    'Account' = 'account' , 
    'Contact' = 'contact' ,
    'BillingGroup' = 'gibs_billinggroup' ,
    'Division' = 'gibs_division' , 
    'ProfessionalBackground' = 'gibs_professionalbackground' ,
    'ProfessionalMembership' = 'gibs_professionalmembership' ,
    'Qualification' = 'gibs_qualification' ,
    'NewEnrolment'= 'new_enrolment'
}

export enum CRMControlType {
    'Boolean' = 'Boolean', 
    'Text' =  'Text',
    'OptionSet' =  'OptionSet',
    'Lookup'  = 'Lookup', 
    'DateTime' = 'DateTime',
    'FileUpload' = 'FileUpload',
    'MultiSelectTags'= 'MultiSelectTags'
}

export enum  CRMHtmlType {
    'Email' = 'email', 
    'Select' =  'select',
    'Text' =  'text',
    'Radio'  = 'radio', 
    'Tel' =  'tel',
    'PastDate' = 'past-date',
    'FutureDate' = 'future-date',
    'LongText' = 'longtext'
}

export enum CRMEntityAttributeKey {
    'ParentCustomerId' = 'parentcustomerid',
    'New_AccountDebtor' = 'new_accountdebtor',
    'LegalTerms' = 'gibs_acceptlegalterms'
}

export enum CRMAddCompanyAttributeKey {
    'ParentCustomerId' = 'parentcustomerid',
    'New_AccountDebtor' = 'new_accountdebtor'   
}

export enum individualCourseFinderDescription {
    InterestAreas = 'Interest areas',
    DeliveryMode = 'Delivery mode',
    Cost = 'Cost',
    Results = 'Results'
}

export function sentenceCase(value: string) {
    var rg = /(^\w{1}|\.\s*\w{1})/gi;
    value = value.toLocaleLowerCase().replace(rg, function(toReplace) {
        return toReplace.toUpperCase();
    });
    return value;
}

export enum ExternalValidationStates
{
    Pending = 'Busy validating delegate enrolment, Please wait...',
    Success = 'Success',
    Error = 'Error',
    None = ''
}

export enum CRMCallState
{
    Pending = 'Creating new item, you may continue adding items',
    Success = 'Success',
    Error = 'Error',
    None = ''
}

export enum SectionDescription {
    ProgrammeRegistrationOptions = 'Programme Registration Options', 
    General = 'General',
    AdditionalDelegates = 'Additional Delegates',
    BillingDetails = 'Billing Details',
    NextOfKinDetails = 'Next of Kin (For Emergency)',
    OrganizationDetails = 'Organization details',
    Qualification = 'Qualification',
    ProfessionalMemberships = 'Professional Memberships',
    ProfessionalBackgroundWorkExperience = 'Professional Background / Work experience',
    Legal = 'Legal',
    PersonalAddress = "Personal address",
    Communication = "Permission for marketing communication",
    AreasOfInterest = "Areas of Interest",
    Payment = "Application Fee Payment Options",
    MaterialDeliveryAddress = "Material delivery address",
    SponsorDetails = "Sponsor details",
    LineManager = "Line manager",
    Mentor = "Mentor",
    Documentation = "Documentation",
    AdditionalInformation = "Additional Information",
    GeneralBackgroundDetail = "General background detail",
    PreferredGroup = "Preferred Group",
    WorkExperience = "Work Experience",
    PersonalInfo = "Personal Information"
}

export enum FormGeneralSectionFormValidatorId {
    BirthDate='BirthDate',
    EmailAddress1='EmailAddress1',
    FamilyStatusCode='FamilyStatusCode',
    Fax='Fax',
    FirstName='FirstName',
    GenderCode='GenderCode',
    CertificateName='CertificateName',
    CountryOfBirthID='CountryOfBirthID',
    Disability='Disability',
    DisabilityDescription='DisabilityDescription',
    HomeLanguageId='HomeLanguageId',
    IdNumberForNonRSAResidents='IdNumberForNonRSAResidents',
    InternationalApplicant='InternationalApplicant',
    InternationalHomeTelephone='InternationalHomeTelephone',
    InternationalMobileNumber='InternationalMobileNumber',
    MaidenName='MaidenName',
    Nationality='Nationality',
    NatureOfDisabilityID='NatureOfDisabilityID',
    OtherDisabilities='OtherDisabilities',
    OtherLeadSource='OtherLeadSource',
    OtherVisaResidenceType='OtherVisaResidenceType',
    PlaceOfBirth='PlaceOfBirth',
    PopulationGroupCode='PopulationGroupCode',
    ReligionId='ReligionId',
    Title='Title',
    UnitForStudentsWithSpecialNeedsOnCampusas='UnitForStudentsWithSpecialNeedsOnCampusas',
    UseAWheelchair='UseAWheelchair',
    VisaResidenceType='VisaResidenceType',
    LastName='LastName',
    LeadSourceCode='LeadSourceCode',
    Middlename='Middlename',
    MobilePhone='MobilePhone',
    Name='Name',
    AccountDebtor='AccountDebtor',
    DietaryRequirement='DietaryRequirement',
    FunctionInOrganisation='FunctionInOrganisation',
    IdNumber='IdNumber',
    PassportNumber='PassportNumber',
    Nickname='Nickname',
    ParentCustomerID='ParentCustomerID',
    Telephone1='Telephone1',
    Telephone2='Telephone2',
    Telephone3='Telephone3'
}

export enum FormGeneralSectionInputId {
    Title='gibs_title_brite_04_01_title',
    Surname='lastname_brite_04_03_surname',
    FirstName='firstname_brite_04_02_firstname',
    PreferredName='nickname_brite_04_04_preferredname',
    PersonalEmailAddress='emailaddress1_brite_04_05_emailaddress',
    DietaryRequirement='new_dietaryrequirement_brite_04_06_dietaryrequirement',
    OtherDietaryRequirements = 'new_dietaryrequirementdescription_brite_04_07_otherdietaryrequirements',
    Organisation='parentcustomerid_brite_04_08_organizationcompany', // brite_04_08_organizationcompany != brite_05_03_organization
    FunctionInOrganisation='new_functioninorganisation_brite_04_09_functioninorganization',
    IDNumber='new_idnumber_brite_04_13_idnumber',
    Nationality='gibs_nationality_brite_04_12_nationality',
    PassportNumber='new_passportnumber_brite_04_14_passportnumber',
    Gender='gendercode_brite_04_17_gender',
    HomeLanguage='gibs_homelanguageid_brite_04_18_homelanguage',
    PopulationGroup='gibs_populationgroupcode_brite_04_19_populationgroup',
    NameToAppearOnCertificate='gibs_certificatename_brite_04_20_nametoappearoncertificate',
    CellphoneNumber='mobilephone_brite_04_21_cellphonenumber',
    DirectNumber='telephone3_brite_04_22_directnumber',
    HowDidYouFindOutAboutUs='leadsourcecode_brite_04_23_aboutus',
    Other='gibs_otherleadsource_brite_04_24_other',
    PhysicallyDisabled='gibs_disability_brite_04_27_physicallydisabled',
    NatureOfDisability='gibs_natureofdisabilityid_brite_04_28_natureofdisability',
    SpecifyOtherConditions='gibs_otherdisabilities_brite_04_29_specifyotherconditions',
    DescribeYouCondition='gibs_disabilitydescription_brite_04_30_describeyourcondition',
    DoYouUseAWheelchair='gibs_useawheelchair_brite_04_31_doyouuseawheelchair',
    DoYouRequireSpecialNeedsUnit='gibs_unitforstudentswithspecialneedsoncampusas_brite_04_32_specialneedsunit',
    MaritalStatus='familystatuscode_brite_04_33_maritalstatus',
    MiddleNames='middlename_brite_04_34_othernames',
    MaidenName='gibs_maidenname_brite_04_35_maidenname',
    DateOfBirth='birthdate_brite_04_36_dateofbirth',
    WorkTelephone='telephone1_brite_04_37_worktelephone',
    FaxNo='fax_brite_04_38_faxnumber',
    HomeTelephone='telephone2_brite_04_39_hometelephone',
    InternationalHomeTelephone='gibs_internationalhometelephone_brite_04_40_internationalhometelephone',
    InternationalMobile='gibs_internationalmobilenumber_brite_04_41_internationalmobile',
    PlaceOfBirthTown='gibs_placeofbirth_brite_04_42_placeofbirthtown',
    PlaceOfBirthCountry='gibs_countryofbirthid_brite_04_43_placeofbirthcountry',
    RSAIdFoNonSACitizens='gibs_idnumberfornonrsaresidents_brite_04_44_rsaidfornonsacitizens',
    TypeOfResidenceVisa='gibs_visaresidencetype_brite_04_45_typeofresidencevisa',
    OtherTypeOfResidenceVisa='gibs_othervisaresidencetype_brite_04_46_othertypeofresidencevisa',
    AreYouAnInternationalStudent='gibs_internationalapplicant_brite_04_47_internationalstudent',
    ReligiousDenomination = 'gibs_religionid_brite_04_48_religiousdenomination',
    // This should not be tied to General section only but to the whole form
    // Also, we consolidated these fields to just the brite fields, no idea why we still have the old concatenated ID ones?
    ResponsibleForAccount = 'new_responsibleforaccount_brite_05_01_responsibleforaccount',
    BillingNewAccountDebtor = 'new_accountdebtor_brite_05_03_organization',
    UseDivisiononInvoice = 'gibs_usedivisiononinvoice_brite_05_05_mustdivisionbeoninvioce',
    DivisionOnInvoice = 'gibs_customerdivision_brite_05_04_divisionasontheinvoice',
    BillingVatNumber = 'gibs_vatnumber_brite_05_13_vatnumber',
    BillingEmailaddress = 'emailaddress_brite_05_11_emailtosendinvoiceto',
    BillingAddressLine1 = 'gibs_billingaddressline1_brite_05_06_postaladdressline1',
    BillingAddressLine2 = 'gibs_billingaddressline1_brite_05_06_postaladdressline2',
    BillingAddressCity = 'gibs_billingaddresscity_brite_05_08_towncity',
    BillingAddressStateProvince = 'gibs_billingaddressstateprovincenew_brite_05_09_province',
    BillingAddressPostalCode = 'gibs_billingaddresspostalcode_brite_05_10_code',
    BillingAddressInternationalZipCode = 'gibs_billingaddressinternationalzipcode_brite_05_18_zipcode',
    BillingAddressCountry = 'gibs_billingaddresscountry_brite_05_17_country',
}

export enum FormQualificationSectionInputId {
    Institution ='gibs_institutionid_brite_12_02_institution',
    AcademicLevel ='gibs_academiclevel_brite_12_03_academiclevel',
    QualificationName ='gibs_name_brite_12_04_degreediplomaname',
    Status ='gibs_status_brite_12_06_status',
    TypeOfStudy ='gibs_typeofstudyid_brite_12_05_typeofstudy',
    StartDate = 'gibs_academicstartdate_brite_12_07_startdate',
    EndDate = 'gibs_academicenddate_brite_12_08_enddate',
    GraduationDate = 'gibs_graduationdate_brite_12_09_graduationdate'
}

export enum FormAdditionalDelegateSectionInputId {
    Title ='gibs_title_brite_07_01_title',
    FirstName ='firstname_brite_07_02_fullnames',
    LastName ='lastname_brite_07_03_surname',
    EmailAddress ='emailaddress_brite_07_04_emailaddress',
    EmailAddress1 ='emailaddress1_brite_07_04_emailaddress'
}

export enum CourseListingFilterType {
    DeliveryType = "DeliveryType",
    InterestAreas = "InterestAreas",
    CourseDuration = "CourseDuration",
    StartDateInterval = "StartDateInterval",
    EventsCourseType = "EventsCourseType",
    ProgrammesCourseType = "ProgrammesCourseType"
}

export enum CourseType {
    ExecutiveCourses = 100000000,
    Academic = 100000001,
    EntrepreneurshipCourses = 100000002,
    ShortCourses = 100000003,
    Forum = 100000004,
    Event = 100000005,
    Conference = 100000007,
    InHouse = 100000008,
    Coaching = 100000009,
    CustomCourses = 100000010,
    Masterclasses = 100000011,
    News = 100000012,
}

export enum EnrollmentStatus {
    Active = 'active',
    Complete = 'complete',
    None = ''
}

export enum Guid {
    Empty = '00000000-0000-0000-0000-000000000000',
    DeadBeef = 'DEADBEEF-DEAD-BEEF-DEAD-BEEFDEADBEEF'
}

export enum EnrolmentDeliveryPreferenceType {
    OnCampus = 100000000,
    OnlineHybrid = 100000001
}

export enum ExperienceLevelType {
    None = 449710000,
    Some = 449710001,
    Much = 449710002,
}

export enum InterestLevelType {
    LessInterested = 449710000,
    Interested = 449710001,
    MoreInterested = 449710002
}


export enum EnrollmentLevels {
    Start = 1,
    Applicationsubmitted = 2,
    Outstandinginformation = 3,
    Detailsverified = 4,
    Assessment = 5,
    Referencesreceived = 6,
    Feedback = 7,
    End = 8,
}

export enum FormLevel {
    Level1 = 'Level1',
    Level2  = 'Level2',
    Level3  = 'Level3',
    Level4  = 'Level4',
}

export enum FormRegistrationOptions {
    ApplyForMyself = 'ApplyForMyself',
    BookForOthers  = 'BookForOthers',
    BookForMyselfAndOthers  = 'BookForMyselfAndOthers',
    InviteOthersToRegister  = 'InviteOthersToRegister',
    RegisterAsADelegate  = 'RegisterAsADelegate',
    RegisterMyselfAnotherGroup = 'RegisterMyselfAnotherGroup'
}

export enum SessionKeys {
    PostLoginRedirectUrl = 'postLoginRedirectUrl',
    BreadcrumbSiteMapEntry = 'breadcrumbSiteMapEntry'
}

/**
 * @description This is a subset of the ones we use from the OG
 * @link https://ogp.me/#types
 */
export enum OGProps{
    'ogTitle' = 'og:title',
    'ogType' = 'og:type',
    'ogImage' = 'og:image',
    'ogUrl' = 'og:url',
    'ogDescription' = 'og:description'
}

export enum OGArticleProps { 
    'articleAuthor'= 'article:author',
    'articlePublishedTime' = 'article:published_time'
}

/**
 * @description These are the OG types, they will have a format namespace:type
 * @link https://ogp.me/#types ,
 * @link Namespace URI: https://ogp.me/ns/music#
 * @link Namespace URI: https://ogp.me/ns/video#
 * @link Namespace URI: https://ogp.me/ns/article#
 *  */ 

export enum OGType {
    'article' = 'article', 
    'video.other' = 'video.other',
    'website' = 'website'
}