import {
    ContactFormValidatorId,
    LoginFormValidatorId,
    SignupFormValidatorId,
    ChangePasswordFormValidatorId,
    ForgotPasswordFormValidatorId,
    ResetPasswordFormValidatorId,
    NewsletterSubscriptionFormValidatorId,
    ShareProgrammeFormValidatorId,
    ApplicationClosedFormValidatorId,
    FormGeneralSectionFormValidatorId,
    CRMHtmlType,
    CourseListingFilterType,
    CourseType,
    EnrollmentStatus,
    EnrollmentLevels
} from "~/utils/enums";

export default defineNuxtPlugin(() => {

    enum FormControlType {
        Boolean = 'Boolean',
        DateTime = 'DateTime',
        Lookup ='Lookup',
        OptionSet = 'OptionSet',
        Text ='Text'
    }; 

    enum VenueType {
        Campus = 449710001,
        Online = 449710002,
        Hybrid = 449710000
    }

    enum EventsCoursesType {
        Events = 100000005,
        Conference = 100000007,
        Forums = 100000004
    }

    enum FacultyDivisionType {
        Permanent = 'PER',
        Adjunct = 'ADJU', 
        ProfessionalAssociate = 'PA',
        PermanentFacultyRands = 'PERRANDS', 
        SalaryAdjunct = 'SADJ',
        InternationalFaculty = 'VIS', 
        ExternalExaminer = 'EXTE',
        Administrator = 'ADM', 
        Other = '99',
        Temporary = 'VAC', 
        TraineeCoach = 'TRANEE',
        Supervisor = 'DOCSUP', 
        EntrepreneurshipDevelopmentAcademyFaculty = 'EDAFAC',
        CLDFaculty = 'CLDFAC', 
        QualityCouncil = 'QCTO',
        ExternalModerator = 'EXTMOD', 
    }

    enum NewsType {
        Article = 'Article',
        PDF = 'PDF',
        Video = 'Video',
        Podcast = 'Podcast',
        ExternalLink = 'ExternalLink'
    }

    enum InterestArea {
        CoachingFacilitationMentorship = 1,
        DigitisationInnovation = 2,
        Entrepreneurship = 3,
        ExecutiveDevelopment = 4,
        Finance = 5,
        General = 6,
        ManagementLeadershipExcellence = 7,
        MarketingSales = 8,
        PersonalOrganisationalEffectiveness = 9,
        Strategy = 10
    }

    enum CourseInterestArea {
        Strategy = "49df744b-7abb-ee11-a569-000d3adf753f",
        Strategy2 = "fec85ccf-7d63-e511-847d-005056894698",
        General = "31167b6f-7abb-ee11-a569-000d3adf753f",
        ExecutiveDevelopment = "8c8f339a-7abb-ee11-a569-000d3adf753f",
        PersonalAndOrganisationalEffectiveness = "1b808cac-7abb-ee11-a569-000d3adf753f",
        CoachingFacilitationMentorship = "97a65822-7f63-e511-847d-005056894698",
        DigitisationInnovation = "72d8fb2b-7f63-e511-847d-005056894698",
        Entrepreneurship = "9493e251-1109-e111-b64e-2c27d7a2e008",
        Finance = "c745103f-7f63-e511-847d-005056894698",
        ManagementLeadershipExcellence = "95a65822-7f63-e511-847d-005056894698",
        MarketingSales = "d58c4673-1109-e111-b64e-2c27d7a2e008",
        PersonalOrganisationalEffectiveness = 9
    }

    enum CourseListingFilterType {
        DeliveryType = "DeliveryType",
        InterestAreas = "InterestAreas",
        CourseDuration = "CourseDuration",
        StartDateInterval = "StartDateInterval",
        EventsCourseType = "EventsCourseType",
        ProgrammesCourseType = "ProgrammesCourseType",
        MediaType = "MediaType",
        Cost = "Cost"
    }

    enum CourseFinderDurationFilterType {
        Short = 1,
        Medium = 2,
        Long = 3 
    }

    enum CourseFinderCostFilterType {
        Under5k = 1,
        From5to25k = 2,
        From25to80k = 3,
        Over80k = 4 
    }

    enum ProgrammeTabNames {
        ApplicationInfo = "Application info",
        Fees = "Fees",
        MinimumRequirements = "Minimum requirements",
        Formats = "Formats",
        Faculty = "Faculty",
        MoreInfo = "More information",
        Overview = "Overview",
        PresenterNew = "About the presenter/s",
        PresenterOld = "About the presenters"
    }

    return {
        provide: {
            EnumFormControlType: FormControlType,
            EnumCourseType: CourseType,
            EnumVenueType: VenueType,
            EnumNewsType: NewsType,
            EnumInterestArea: InterestArea,
            EnumCourseInterestArea: CourseInterestArea,
            EnumContactFormValidatorId: ContactFormValidatorId,
            EnumLoginFormValidatorId: LoginFormValidatorId,
            EnumSignupFormValidatorId: SignupFormValidatorId,
            EnumChangePasswordFormValidatorId: ChangePasswordFormValidatorId,
            EnumForgotPasswordFormValidatorId: ForgotPasswordFormValidatorId,
            EnumCourseListingFilterType: CourseListingFilterType,
            EnumResetPasswordFormValidatorId: ResetPasswordFormValidatorId,
            EnumNewsletterSubscriptionFormValidatorId: NewsletterSubscriptionFormValidatorId,
            EnumShareProgrammeFormValidatorId: ShareProgrammeFormValidatorId,
            EnumProgrammeTabNames: ProgrammeTabNames,
            EnumEventsCoursesType: EventsCoursesType,
            EnumFacultyDivisionType: FacultyDivisionType,
            EnumFormGeneralSectionFormValidatorId: FormGeneralSectionFormValidatorId,
            EnumCRMHtmlType: CRMHtmlType,
            EnumEnrollmentStatus: EnrollmentStatus,
            EnumCourseFinderDurationFilterType: CourseFinderDurationFilterType,
            EnumCourseFinderCostFilterType: CourseFinderCostFilterType,
            EnumEnrollmentLevels: EnrollmentLevels,
            EnumApplicationClosedFormValidatorId: ApplicationClosedFormValidatorId
        }
    }
})

