
import { defineStore } from 'pinia';

export const useNotificationStore = defineStore('notificationStore', () => {
    // Props
    var count: number = 0;
    var notificationList = ref(new Array<INotification>());

    // Getters
    const getNotificationList = computed(
        () => {
            return notificationList.value;
        }
    );

   //action
    function addNotification(notification: INotification) {
        notification.date = new Date();
        notificationList.value.push(notification);
        count ++;
    }
    function clearNotifications() {
        notificationList.value.splice(0, notificationList.value.length);
        count = 0;
    }
    return {
        getNotificationList,
        addNotification,
        clearNotifications
    }
});