// import { alphaNumericValidator, emailValidator, notEmptyValidator, optionSelected } from "utils/validators";

export default defineNuxtPlugin(() => {  

    return {
        provide: {
            emailValidator,
            notEmptyValidator,
            isRequired: notEmptyValidator,
            optionSelected,
            alphaNumericValidator,
            validateText,
            validateContactNumber
        }
    }
})

export function validateText(text: string): boolean {
    const regex = /&|~|!|@|#|\$|%|\^|\*|\(|\)|_|\\|\+|=|"/g;
    return !regex.test(text) && text.length <= 50 && !/\d/.test(text);
}
  
export function validateContactNumber(contactNumber: string): boolean {
    const regex = /^\+\d{2}\s\d{2}\s\d{3}\s\d{4}$/;
    return regex.test(contactNumber);
}