import revive_payload_client_4sVQNw7RlN from "C:/GibsAgent/_work/4/s/frontend/web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/GibsAgent/_work/4/s/frontend/web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/GibsAgent/_work/4/s/frontend/web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import router_options_client_6LwEXFRvwx from "C:/GibsAgent/_work/4/s/frontend/web/src/plugins/router.options.client.ts";
import check_outdated_build_client_8vK7RkfGxZ from "C:/GibsAgent/_work/4/s/frontend/web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "C:/GibsAgent/_work/4/s/frontend/web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/GibsAgent/_work/4/s/frontend/web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/GibsAgent/_work/4/s/frontend/web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eskviOYyLt from "C:/GibsAgent/_work/4/s/frontend/web/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "C:/GibsAgent/_work/4/s/frontend/web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vimeo_player_ADJTW6v1Rj from "C:/GibsAgent/_work/4/s/frontend/web/src/plugins/vimeo-player.js";
import _0_error_client_uw7q6tmc64 from "C:/GibsAgent/_work/4/s/frontend/web/src/plugins/0.error.client.ts";
import _1_router_client_ixddMJmhBv from "C:/GibsAgent/_work/4/s/frontend/web/src/plugins/1.router.client.ts";
import enums_GNhpWSbmI9 from "C:/GibsAgent/_work/4/s/frontend/web/src/plugins/enums.ts";
import functions_client_vgs2XW2JGt from "C:/GibsAgent/_work/4/s/frontend/web/src/plugins/functions.client.ts";
import validations_plugin_nJAxurMhJm from "C:/GibsAgent/_work/4/s/frontend/web/src/plugins/validations-plugin.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  router_options_client_6LwEXFRvwx,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eskviOYyLt,
  chunk_reload_client_UciE0i6zes,
  vimeo_player_ADJTW6v1Rj,
  _0_error_client_uw7q6tmc64,
  _1_router_client_ixddMJmhBv,
  enums_GNhpWSbmI9,
  functions_client_vgs2XW2JGt,
  validations_plugin_nJAxurMhJm
]