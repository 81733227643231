export default defineNuxtPlugin({
    name: 'scroll-to-top-plugin',
    enforce: 'pre',
    hooks: {
        'page:finish'() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }
})