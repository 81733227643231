<template>
    <div id="gibs-permanent-notification-component" class="gibs-permanent-notification-component" v-if="displayPermanentNotification" v-show="displayPermanentNotification">
        <div id="gibs-permanent-notification-item" class="gibs-permanent-notification-item" :class="checkNotificationType(permanentNotificationEnabled)" >
            <template v-if="typeof(permanentNotificationEnabled) !== 'undefined'">
                <div class="gibs-notification-item-wrapper">
                    <div class="gibs-notification-icon-container">
                        <nuxt-icon class="gibs-notification-icon" :name="iconChoice(permanentNotificationEnabled)" />
                    </div>
                    <div class="gibs-notification-content-container">
                        <div class="gibs-notification-body">
                            <span class="notification-body-type">
                                {{ permanentNotificationEnabled.type }}: 
                            </span>
                            <span class="notification-body-title">
                                {{ permanentNotificationEnabled.title }}
                            </span>
                            <span class="notification-body-text">
                                &nbsp{{ permanentNotificationEnabled.body }}
                            </span>
                        </div>
                    </div>
                </div>
                <button class="gibs-notification-close" @click="closePermanentNotification()" >
                    <nuxt-icon class="notification-close-btn" name="buttons/close-cross"/>
                </button>
            </template>
            
        </div>
    </div>
    
    <div id="gibs-popup-notification-component" class="gibs-popup-notification-component" v-show="store.getNotificationList.length != 0">
        <div v-show="false" class="gibs-notification-background"></div>
        <div class="gibs-notification-container">
            <button id="gibs-notification-expand-button" class="gibs-notification-expand-button" @click="expandCollapseNotification()" />
            <div id="gibs-notification-list" class="gibs-notification-list">
                <div v-for="(notification, index) in notificationListArray" class="initial-load" :class="checkNotificationType(notification)">
                    <div class="gibs-notification-item-wrapper">
                        <div class="gibs-notification-icon-container">
                            <nuxt-icon v-if="notification.type == 'success'" class="gibs-notification-icon" name="notifications/success" />
                            <nuxt-icon v-else-if="notification.type == 'error'" class="gibs-notification-icon" name="notifications/error" />
                            <nuxt-icon v-else-if="notification.type == 'note'" class="gibs-notification-icon" name="notifications/note" />
                        </div>
                        <div class="gibs-notification-content-container">
                            <div class="gibs-notification-body">
                                <span class="notification-body-type">
                                    {{ notification.type }}: 
                                </span>
                                <span class="notification-body-title">
                                    {{ notification.title }}
                                </span>
                                <span class="notification-body-text">
                                    &nbsp{{ notification.body }}
                                </span>
                            </div>
                            <NuxtLink v-if="notification.buttonText! && notification.buttonText.length > 0" 
                                :to="notification.LinkUrl"
                                class="gibs-notification-inline-button"
                            >
                            <span>
                                {{ notification.buttonText }}
                            </span>
                            </NuxtLink>
                        </div>
                    </div>
                    <button id="notification-close" class="gibs-notification-close" @click="closeNotification(index)" aria-label="Close panel">
                        <nuxt-icon class="notification-close-btn" name="buttons/close-cross"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useNotificationStore } from '~/stores/notification';

    const config = useRuntimeConfig();
    // const permanentNotificationEnabled = config.public.permanentNotificationMessage;
    const permanentNotificationEnabled = ref();
   
    // Any page that stores the notification in the useNotificationStore can be accessed here.
    const store = useNotificationStore();
    
    const checkNotificationType = computed(() => {
        return  (notification: INotification) => {
            
            if (typeof(notification) !== 'undefined' && typeof(notification.type) !== 'undefined') {
                if (notification.type == "success") {
                    return "success";
                } else if (notification.type == "error") {
                    return "error";
                } else if (notification.type == "note") {
                    return "note";
                };
            }
        }
    });

    const displayPermanentNotification = computed(() => {
        const date = new Date();

        if (typeof(permanentNotificationEnabled.value) !== 'undefined' && typeof(permanentNotificationEnabled.value.expiryDate) !== 'undefined') {
            const expiryDate = new Date(permanentNotificationEnabled.value.expiryDate);

            // check if display is true, and if current date hasn't passed the expiryDate (date < expiryDate)
            if (permanentNotificationEnabled.value.display && date < expiryDate) {
                return true;
            }
        }
        return false;
    });

    const iconChoice = computed(() => {
        return (permanentNotification) => {
            let iconName = '';
            if (permanentNotification.type == 'success') {
                iconName = 'notifications/success';
            }
            else if (permanentNotification.type == 'error') {
                iconName = 'notifications/error';
            }
            else if (permanentNotification.type == 'note'){
                iconName = 'notifications/note';
            }
            return iconName;
        }
    });

    var showNoteInCollapse = true;
    const notificationListArray = ref(new Array<INotification>());
    var lengthOfArray = 0;
    var listOfNotifications: INotification[] = ref(store.getNotificationList).value;

    function incomingNotification() {
        notificationListArray.value = store.getNotificationList;
        lengthOfArray = notificationListArray.value.length;

        // performs animation
        setTimeout(() => {
            var newNotificationEl = document.querySelectorAll(".initial-load");
            var lengthOfOpen = newNotificationEl.length;

            if (showNoteInCollapse && notificationListArray.value && notificationListArray.value[lengthOfArray - 1]) {
                if (notificationListArray.value[lengthOfArray - 1].timeout == false) {
                    newNotificationEl[lengthOfOpen - 1].classList.add("gibs-notification-item", "open-notification");
                } else {
                    newNotificationEl[lengthOfOpen - 1].classList.add("gibs-notification-item", "open-notification", "timeout-notification");
                }
            } else if (!showNoteInCollapse && notificationListArray.value && notificationListArray.value[lengthOfArray - 1]) {
                if (notificationListArray.value[lengthOfArray - 1].timeout == false) {
                    newNotificationEl[lengthOfOpen - 1].classList.add("gibs-notification-item", "expanded-notification");
                } else {
                    newNotificationEl[lengthOfOpen - 1].classList.add("gibs-notification-item", "expanded-notification", "timeout-notification");
                }
            }


            addLatestNotificationClasses();
            
            closeNotificationTimer(6000);
        }, 20);
    
        return listOfNotifications;
    };

    function closeNotification(index: number) {
        var allNotifications = document.querySelectorAll(".initial-load");
        if (allNotifications && allNotifications[index] && allNotifications != undefined && allNotifications[index] != undefined) {
            allNotifications[index].classList.add("close-notification");
            allNotifications[index].classList.remove("open-notification");

            setTimeout(() => {
                if (allNotifications[index].classList.contains("close-notification")) {
                    allNotifications[index].classList.add("!hidden");
                    allNotifications[index].classList.remove("expanded-notification");               
                }
                reshuffleLatestNotificationClasses(index);
    
                // clear the listOfNotifications array when all notifitions are closed and hidden
                var closedNotifications = document.getElementsByClassName("close-notification");
                if (closedNotifications && (closedNotifications.length == allNotifications.length)) {
                    store.clearNotifications();
                    showNoteInCollapse = true;
    
                    // Clears all timeouts in queue after clearing the notification queue
                    const timeoutId = window.setTimeout(() => {
                        for (let i = timeoutId; i >= 0; i--) {
                            window.clearTimeout(i);
                        }
                    }, 0);
    
                    var expandButton = document.getElementById("gibs-notification-expand-button");
                    if (expandButton!.classList.contains("expanded-notification-btn")) {
                        expandButton!.classList.toggle("expanded-notification-btn");
                    }
                }
            }, 150);
        }

    }

    function addLatestNotificationClasses() {
        var allOpenNotifications = document.getElementsByClassName("open-notification");
        var lengthOfOpen = allOpenNotifications.length;
        
        if (lengthOfOpen == 1) {
            allOpenNotifications[lengthOfOpen - 1].classList.add("latest-notification-1");
        } else if (lengthOfOpen == 2) {
            allOpenNotifications[lengthOfOpen - 2].classList.remove("latest-notification-1");
            allOpenNotifications[lengthOfOpen - 2].classList.add("latest-notification-2");
            allOpenNotifications[lengthOfOpen - 1].classList.add("latest-notification-1");
        } else if (lengthOfOpen == 3) {
            allOpenNotifications[lengthOfOpen - 3].classList.remove("latest-notification-2");
            allOpenNotifications[lengthOfOpen - 3].classList.add("latest-notification-3");
            allOpenNotifications[lengthOfOpen - 2].classList.remove("latest-notification-1");
            allOpenNotifications[lengthOfOpen - 2].classList.add("latest-notification-2");
            allOpenNotifications[lengthOfOpen - 1].classList.add("latest-notification-1");
        } else if (lengthOfOpen >= 4) {
            allOpenNotifications[lengthOfOpen - 4].classList.remove("latest-notification-3");
            allOpenNotifications[lengthOfOpen - 3].classList.remove("latest-notification-2");
            allOpenNotifications[lengthOfOpen - 3].classList.add("latest-notification-3");
            allOpenNotifications[lengthOfOpen - 2].classList.remove("latest-notification-1");
            allOpenNotifications[lengthOfOpen - 2].classList.add("latest-notification-2");
            allOpenNotifications[lengthOfOpen - 1].classList.add("latest-notification-1");
        }
    }

    function reshuffleLatestNotificationClasses(index: number) {
        var allNotifications = document.getElementsByClassName("gibs-notification-item");
        var allOpenNotifications = document.getElementsByClassName("open-notification");
        var lengthOfOpen = allOpenNotifications.length;
        if (allNotifications && allNotifications[index]) {
            if (allNotifications[index].classList.contains("latest-notification-1") && lengthOfOpen > 0) {
                allNotifications[index].classList.remove("latest-notification-1");
                allOpenNotifications[lengthOfOpen - 1].classList.remove("latest-notification-2");
                allOpenNotifications[lengthOfOpen - 1].classList.add("latest-notification-1");
                if (lengthOfOpen == 2) {
                    allOpenNotifications[lengthOfOpen - 2].classList.remove("latest-notification-3");
                    allOpenNotifications[lengthOfOpen - 2].classList.add("latest-notification-2");
                }
                if (lengthOfOpen > 2) {
                    allOpenNotifications[lengthOfOpen - 2].classList.remove("latest-notification-3");
                    allOpenNotifications[lengthOfOpen - 2].classList.add("latest-notification-2");
                    allOpenNotifications[lengthOfOpen - 3].classList.add("latest-notification-3");
                }
            } else if (allNotifications[index].classList.contains("latest-notification-2")) {
                allNotifications[index].classList.remove("latest-notification-2");
                if (lengthOfOpen > 2) {
                    allOpenNotifications[lengthOfOpen - 2].classList.remove("latest-notification-3");
                    allOpenNotifications[lengthOfOpen - 2].classList.add("latest-notification-2");
                    allOpenNotifications[lengthOfOpen - 3].classList.add("latest-notification-3");
                } else if (lengthOfOpen == 2) {
                    allOpenNotifications[lengthOfOpen - 2].classList.remove("latest-notification-3");
                    allOpenNotifications[lengthOfOpen - 2].classList.add("latest-notification-2");
                }
            } else if (allNotifications[index].classList.contains("latest-notification-3")) {
                allNotifications[index].classList.remove("latest-notification-3");
                if (lengthOfOpen >= 3) {
                    allOpenNotifications[lengthOfOpen - 3].classList.add("latest-notification-3");
                }
            }
        }
    }



    function expandCollapseNotification() {
        var expandButton = document.getElementById("gibs-notification-expand-button");
        var allOpenNotifications = document.querySelectorAll(".open-notification");
        var lengthOfOpen = allOpenNotifications.length;
        if (showNoteInCollapse) {
            showNoteInCollapse = false;
            expandButton!.classList.toggle("expanded-notification-btn");
    
            allOpenNotifications.forEach(openNotification => {
                openNotification.classList.add("expanded-notification");
                openNotification.classList.remove("open-notification");
    
                openNotification.classList.remove("latest-notification-1");
                openNotification.classList.remove("latest-notification-2");
                openNotification.classList.remove("latest-notification-3");
            })
        } else {
            showNoteInCollapse = true;
            var allExpandedNotifications = document.querySelectorAll(".expanded-notification");
            expandButton!.classList.toggle("expanded-notification-btn");

            allExpandedNotifications.forEach(expandedNotification => {
                expandedNotification.classList.remove("expanded-notification");
                expandedNotification.classList.add("open-notification");
            })

            // refetch the open elements
            allOpenNotifications = document.querySelectorAll(".open-notification");
            lengthOfOpen = allOpenNotifications.length;

            if (lengthOfOpen > 0) {
                allOpenNotifications[lengthOfOpen - 1].classList.add("latest-notification-1");
                if (lengthOfOpen == 2) {
                    allOpenNotifications[lengthOfOpen - 2].classList.add("latest-notification-2");
                } 
                if (lengthOfOpen >= 3) {
                    allOpenNotifications[lengthOfOpen - 2].classList.add("latest-notification-2");
                    allOpenNotifications[lengthOfOpen - 3].classList.add("latest-notification-3");
                } 
            } else {
                store.clearNotifications();
            }
        }
    }

    function rearrangeNotification() {
        notificationListArray.value = store.getNotificationList;
        setTimeout(() => {
            var newNotificationEl = document.querySelectorAll(".initial-load");
            newNotificationEl.forEach(notification => {
                notification.classList.add("gibs-notification-item", "open-notification");
            })
            addLatestNotificationClasses();
        }, 20);

    }

    function closeNotificationTimer(seconds:number) {
        var allNotificationsEl = document.querySelectorAll('.initial-load');
        allNotificationsEl.forEach((notification, index) => {
            if (notificationListArray.value[index].timeout == true) {
                if (notificationListArray.value[index].timer) {
                    setTimeout(() => {
                        closeNotification(index);
                    }, notificationListArray.value[index].timer);
                } else {
                    setTimeout(() => {
                        closeNotification(index);
                    }, seconds);
                }
            } 
        });
    }



    function closePermanentNotification() {
        const notificationEl = document.getElementById("gibs-permanent-notification-component");
        notificationEl?.classList.add("hidden");
    }

    onBeforeMount(() => {
        let  notificationJson: INotification;

        fetchJsonData('/sitenotification')
			.then((response: string | Object) => {
               

                if (response instanceof Object) {
                    notificationJson = response as INotification;
                }
                else {
                    notificationJson = JSON.parse(response);
                }
               
				const notificationType = notificationJson.type.toLowerCase();

                permanentNotificationEnabled.value =  {
					type: notificationType,
					title: notificationJson.title,
					body: notificationJson.body,
					date: new Date(),
					display: notificationJson.display,
                    expiryDate: notificationJson.expiryDate
				};
			})
			.catch((err) => {
				console.error(`/HomePage/Permanent Notification Error: ${JSON.stringify(err, null, 2)}`);
			})
    });

    onMounted(() => {
        rearrangeNotification();
    });

    watch(store.getNotificationList, () => {
        if (store.getNotificationList.length > 0) {
            incomingNotification();
        }

        // error handling when too many notifications comes through at once
        const errorNotifications = document.querySelectorAll(".initial-load.error") as NodeListOf<HTMLElement>;
        errorNotifications.forEach((error: HTMLElement) => {
            // notification classes will never be less than 3 unless something went wrong
            if (error.classList.length < 3) {
            store.clearNotifications();
            showNoteInCollapse = true;
            }
        });

        // clear notification if notification is getting flooded with errors
        if (errorNotifications.length > 20) {
            store.clearNotifications();
            showNoteInCollapse = true;
        }
    });

</script>