export function currencyFormat(amount: number, currencyType: string | null | undefined) {
    // Default to ZAR if currencyType is null, empty, or undefined
    const currency = currencyType ? currencyType.trim() : "South African Rand"; 
 
    const currencyMap = {
        "South African Rand": { code: "ZAR", symbol: "R" },
        "US Dollar": { code: "USD", symbol: "$" },
        "Euro": { code: "EUR", symbol: "€" },
        "British Pound": { code: "GBP", symbol: "£" }, 
    };
 
    const { code, symbol } = currencyMap[currency as keyof typeof currencyMap] || currencyMap["South African Rand"];

    const optionsCur = {
        style: "currency",
        currency: code,
        symbol: symbol,
        currencyDisplay: "narrowSymbol",
        minimumFractionDigits: 0, 
        maximumFractionDigits: 0
    };
 
    return new Intl.NumberFormat('en', optionsCur).format(amount);
}