<template v-cloak>
	<div>
		<NuxtLoadingIndicator class="gibs-top-loader-bar" />
		<div>
			<NuxtLayout :layoutView="layoutView">
				<NuxtPage :windowView="windowView" :currentUrl="currentUrl"/>
			</NuxtLayout>
		</div>
		<div id="notifications" class="gibs-notification-component">
			<GibsNotifications />
		</div>
	</div>
</template>

<script lang="ts">
	export default {
		data() {
			return {
				showTestNav: false,
				refreshComponent: 0,
				mobileWidth: 0,
				tabletWidth: 0,
				desktopWidth: 1820,
				currentWidth: 0,
				layoutView: {
					view: "",
					windowWidth: 0,
					refresh: 0
				},
				windowView: {
					view: "",
					slidesCol: 1,
					windowWidth: 0,
					refresh: 0
				},
				currentUrl: useRequestURL().href 
			}
		},
		mounted() {
			this.mobileWidth = Number(useRuntimeConfig().public.webMobileMedia ?? "800"),
			this.tabletWidth = Number(useRuntimeConfig().public.webTabletMedia ?? "1280"),
			this.desktopWidth = 1820,
			this.currentWidth = window.innerWidth;
			this.handleResize();
			this.checkLayout(this.currentWidth);
			window.addEventListener('resize', this.handleResize);
		},
		destroyed() {
			if (process.client) {
			window.removeEventListener('resize', this.handleResize);
			}
		},
		methods: {
			handleResize() {
			this.layoutView.windowWidth = window.innerWidth;
			this.windowView.windowWidth = window.innerWidth;
			if (((this.currentWidth <= this.mobileWidth && this.layoutView.windowWidth >= this.mobileWidth) ||
				(this.currentWidth >= this.mobileWidth && this.currentWidth < this.tabletWidth && (this.layoutView.windowWidth <= this.mobileWidth || this.layoutView.windowWidth >= this.tabletWidth)) ||
				(this.currentWidth > this.tabletWidth && this.currentWidth <= this.desktopWidth && (this.layoutView.windowWidth <= this.tabletWidth || this.layoutView.windowWidth >= this.desktopWidth)) ||
				(this.currentWidth > this.desktopWidth && this.layoutView.windowWidth <= this.desktopWidth)) &&
				this.layoutView.windowWidth != this.currentWidth) {
				this.currentWidth = window.innerWidth;
				this.checkLayout(this.currentWidth);
			}
			},
			checkLayout(currentWidth: number) {
			if (currentWidth < this.mobileWidth) {
				this.windowView = {
				view: "mobile",
				slidesCol: 1,
				windowWidth: currentWidth,
				refresh: this.windowView.refresh++,
				};

				this.layoutView = {
				view: "mobile",
				windowWidth: currentWidth,
				refresh: this.layoutView.refresh++,
				};
			} else if (currentWidth < this.tabletWidth) {
				this.windowView = {
				view: "tablet",
				slidesCol: 2,
				windowWidth: currentWidth,
				refresh: this.windowView.refresh++,
				};

				this.layoutView = {
				view: "tablet",
				windowWidth: currentWidth,
				refresh: this.layoutView.refresh++,
				};
			} else if (currentWidth < this.desktopWidth) {
				this.windowView = {
				view: "desktop",
				slidesCol: 3,
				windowWidth: currentWidth,
				refresh: this.windowView.refresh++,
				};

				this.layoutView = {
				view: "desktop",
				windowWidth: currentWidth,
				refresh: this.layoutView.refresh++,
				};
			} else {
				this.windowView = {
				view: "ultraWide",
				slidesCol: 5,
				windowWidth: currentWidth,
				refresh: this.windowView.refresh++,
				};

				this.layoutView = {
				view: "ultraWide",
				windowWidth: currentWidth,
				refresh: this.layoutView.refresh++,
				};
			}
			}
		},
	}

</script>